window.UserManagementAppConfig = {
  USER_MANAGEMENT_API_URL: "#{userManagementApiUrl}#",
  PORTAL_API_URL: "#{portalApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  PORTAL_URL: "#{portalUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  ENVIRONMENT: "#{environment}#",
  SIGNUP_URL: "#{signUpUrl}#",
  DATADOG_PRIVACY_LEVEL: "#{dataDogPrivacyLevel}#",
  AUTH0_DOMAIN: "#{auth0Domain}#",
  AUTH0_CLIENTID: "#{auth0ClientId}#",
  TURNSTILE_SITE_KEY: "#{turnstileSiteKey}#",
  DEFAULT_TO_AUTH0: "#{defaultToAuth0}#",
};
